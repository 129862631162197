<template> 
	<div>
		<div class="admin-design-box">
			<div class="mt20 bg-w pd40">
				<div style="width: 1000px;">
					<a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
						<template v-for="(item,index) in temps">
							<show-input v-if="item.key=='input'" :datas="temps[index]"></show-input>
							<show-textarea v-if="item.key=='textarea'" :datas="temps[index]"></show-textarea>
							<show-radio v-if="item.key=='radio'" :datas="temps[index]"></show-radio>
							<show-checkbox v-if="item.key=='checkbox'" :datas="temps[index]"></show-checkbox>
							<show-select v-if="item.key=='select'" :datas="temps[index]"></show-select>
							<show-switch v-if="item.key=='switch'" :datas="temps[index]"></show-switch>
						</template>
					</a-form-model>	
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import showInput from './show/admin/input.vue';
	import showTextarea from './show/admin/textarea.vue';
	import showRadio from './show/admin/radio.vue';
	import showCheckbox from './show/admin/checkbox.vue';
	import showSelect from './show/admin/select.vue';
	import showSwitch from './show/admin/switch.vue';
	export default{
		components:{
			showInput,
			showTextarea,
			showRadio,
			showCheckbox,
			showSelect,
			showSwitch,
		},
		props:{
			temps:{
				type:Array,
				default:function(){
					return new Array;
				}
			}
		},
		data(){
			return{
				labelCol: { span: 4 },
				wrapperCol: { span: 10 },
				form:{}
			}
		},
		created(){
			
		},
		computed:{
			
			
		}
	}
</script>

<style>
	.admin-design-box{
		width: 700px;
		background: #F7F8FA;
		border: 1px solid #DCDFE6;
		
	}
</style>
