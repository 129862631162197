<template> 
	<div>
		<div class="mobile-design-box">
			<div :style="{background:mainColor}">
				<img src="../../../assets/image/NavigationBarWhite@2x.png" class="mobile-box-back-img-header"/>
			</div>
			
			<div class="mobile-design-plugins-main" :style="{background:temps.home_bg}">
				<div class="mobile-design-content">
					<div v-for="(item,index) in temps" :key="index" :class="{btline:index>0}"  class="mobile-plugins-item">
						<show-input v-if="item.key=='input'" :datas="temps[index]"></show-input>
						<show-textarea v-if="item.key=='textarea'" :datas="temps[index]"></show-textarea>
						<show-radio v-if="item.key=='radio'" :datas="temps[index]"></show-radio>
						<show-checkbox v-if="item.key=='checkbox'" :datas="temps[index]"></show-checkbox>
						<show-select v-if="item.key=='select'" :datas="temps[index]"></show-select>
						<show-switch v-if="item.key=='switch'" :datas="temps[index]"></show-switch>
						<!-- <show-search v-if="item.key == 'search'" :mainColor="mainColor" :datas="temps.plugins[index]"></show-search>
						<show-banner v-if="item.key == 'banner'" :mainColor="mainColor" :datas="temps.plugins[index]"></show-banner>
						<show-icon v-if="item.key == 'icon'" :mainColor="mainColor" :datas="temps.plugins[index]"></show-icon>
						<show-pics v-if="item.key == 'pics'" :mainColor="mainColor" :datas="temps.plugins[index]"></show-pics>
						<show-video v-if="item.key == 'video'" :mainColor="mainColor" :datas="temps.plugins[index]"></show-video>
						<show-store v-if="item.key == 'store'" :mainColor="mainColor" :datas="temps.plugins[index]"></show-store>
						<show-product v-if="item.key == 'product'" :mainColor="mainColor" :datas="temps.plugins[index]"></show-product>
						<show-coupon v-if="item.key == 'coupon'" :mainColor="mainColor" :datas="temps.plugins[index]"></show-coupon>
						<show-mendian v-if="item.key == 'mendian'" :mainColor="mainColor" :datas="temps.plugins[index]"></show-mendian>
						<show-look v-if="item.key == 'look'" :mainColor="mainColor" :datas="temps.plugins[index]"></show-look> -->
					</div>
				</div>
				
			</div>
	
			<div class="mobile-design-box-footer">
				<div class="mobile-show-footer-main">
					<div v-for="(item,index) in getFooters" :key="index" class="item" :style="getFooterW">
						<div>
							<i class="iconfont ft22" :class="item.icon" :style="{color:index == 0 ?  mainColor : '#AEB2C1'}"></i>
						</div>
						<div class="ft12 mt5" :style="{color:index == 0 ?  mainColor : '#AEB2C1'}">
							{{item.name}}
						</div>
					</div>
				</div>
				<div class="flex center mt16">
					<div class="mobile-bd-line"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import showInput from './show/mobile/input.vue';
	import showTextarea from './show/mobile/textarea.vue';
	import showRadio from './show/mobile/radio.vue';
	import showCheckbox from './show/mobile/checkbox.vue';
	import showSelect from './show/mobile/select.vue';
	import showSwitch from './show/mobile/switch.vue';
	export default{
		components:{
			showInput,
			showTextarea,
			showRadio,
			showCheckbox,
			showSelect,
			showSwitch,
		},
		props:{
			temps:{
				type:Array,
				default:function(){
					return new Array;
				}
			}
		},
		data(){
			return{
				
			}
		},
		created(){
			
		},
		computed:{
			getFooterW(){
				let width = 50;
				if(this.getFooters.length > 0){
					width = 100 / this.getFooters.length;
				}
				return 'width:'+width + '%;';
			},
			getFooters(){
				let arr = new Array;
				arr.push({name:'首页',model:'index',icon:'icontabbar01'});
				arr.push({name:'我的',model:'member',icon:'icontabbar03'});
				return arr;
			},
			mainColor(){
				return this.temps.main_color ? this.temps.main_color :'#00BA26';
			},
			
		}
	}
</script>

<style>
	.mobile-design-box{
		width: 377px;
		background: #F7F8FA;
		border: 1px solid #DCDFE6;
	}
	
	.mobile-design-plugins-main{
		min-height: 200px;
		width: 375px;
		padding: 16px 15px;
		
	}
	
	.mobile-design-content{
		border-radius: 12px;
		background-color: #ffffff;
		padding: 0 15px;
	}
	.mobile-plugins-item{
		width: 100%;
		position: relative;
	}
	.mobile-box-back-img-header{
		width: 375px;
	}

	.product-img-box{
		width: 80px;
		height: 80px;
		position: relative;
	}
	.product-img-box .product-img{
		width: 100%;
		height: 80px;
		border-radius: 4px;
		object-fit: cover
	}
	.product-r{
		width: calc(100% - 80px);
	}
	.product-img-box.mini,.product-img-box.mini .product-img{
		width: 100px;
		height: 100px;
	}
	.product-r.mini{
		width: calc(100% - 100px);
	}
	.product-img-box.small,.product-img-box.small .product-img{
		width: 120px;
		height: 120px;
	}
	.product-r.small{
		width: calc(100% - 120px);
	}
	.product-img-box.mid,.product-img-box.mid .product-img{
		width: 130px;
		height: 130px;
	}
	.product-r.mid{
		width: calc(100% - 130px);
	}
	.product-img-box.big,.product-img-box.big .product-img{
		width: 150px;
		height: 150px;
	}
	.product-r.big{
		width: calc(100% - 150px);
	}
	.product-img-box.max,.product-img-box.max .product-img{
		width: 165px;
		height: 165px;
	}
	.product-r.max{
		width: calc(100% - 165px);
	}
	.btn-buy{
		width: 60px;
		height: 24px;
		background: #00BA26;
		border-radius: 12px;
		text-align: center;
		line-height: 24px;
		font-size: 14px;
		color:#FFFFFF;
	}
	
	.product-tag,.pintuan-tag{
		position: absolute;
		left: 4px;
		top: 4px;
		height: 18px;
		background: linear-gradient(270deg, #FF6600 0%, #FFAA00 100%);
		border-radius: 4px;
		padding: 0 6px;
		line-height: 18px;
		font-size: 12px;
		color:#FFFFFF;
	}
	.pintuan-tag{
		background: rgba(0,0,0,.4);
	}
	.plugin-empty{
		width: 100%;
		height: 100%;
		text-align: center;
		line-height: 100%;
		color:#666666;
		font-size: 16px;
	}
		
	.mobile-design-box-footer{
		width: 375px;
		height: 83px;
		background: #FFFFFF;
		box-shadow: 0px -2px 8px 0px rgba(0, 0, 0, 0.04);
	}
	
	.mobile-show-footer-main{
		height: 50px;
		display: flex;
		align-items: center;
	}
	.mobile-show-footer-main .item{
		height: 50px;
		text-align: center;
		padding-top: 5px;
	}
	.mobile-bd-line{
		width: 134px;
		height: 5px;
		background: rgba(0, 0, 0, 0.9);
		border-radius: 2px;
	}
	
	.service-item-l{
		width: 120px;
		height: 90px;
		position: relative;
		overflow: hidden;
	}
	.service-item-r{
		width: calc(100% - 120px);
	}
	.service-item-l .service-img{
		width: 120px;
		height: 90px;
		border-radius: 4px;
	}
	.service-item-l .miaosha-tag{
		width: 48px;
		height: 48px;
		position: absolute;
		left: 0;
		top: 0;
	}
	.service-item-l .djs-tag{
		width: 100%;
		height: 20px;
		background: rgba(0,0,0,.6);
		display: flex;
		justify-content: center;
		align-items: center;
		font-size:12px;
		color:#FFFFFF;
		position: absolute;
		left: 0;
		bottom: 0;
	}
	.service-item-l.small{
		width: 100px;
		height: 80px;
	}
	
	.service-item-l.small .miaosha-tag{
		width: 40px;
		height:40px;
	}
	
	.service-item-l.small .service-img{
		width: 100px;
		height: 80px;
	}
	.service-item-r.small{
		width: calc(100% - 100px);
	}
	.tag-save{
		padding: 0 8px;
		height: 20px;
		background: #FFF0CF;
		border-radius: 10px;
		color:#FF6600;
		line-height: 20px;
		font-size: 12px;
		font-weight: 600;
	}
	.btline{
		border-top: 1px solid #EBEDF5;
	}
</style>
