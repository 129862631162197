<template>
	<div class="pd30">
		<a-spin :spinning="loading">
			<div class="flex alcenter space">
				<div class="ft20 cl-black cl-main ftw500">{{getTitle}}</div>
				<div>
					<a-button @click="$router.go(-1)">返回</a-button>
				</div>
			</div>

			<div class="mt20">
				<div class="bg-w" style="min-height: 800px;">
					<div>
						<a-alert message="请在右侧区域进行设置，左侧仅为视图效果。" banner />
					</div>

					<div class="mt30">
						<div class="flex center">
							<div>
								<show-mobile :temps="temps" v-if="scope ==1"></show-mobile>
								<show-admin :temps="temps" v-if="scope ==2"></show-admin>
							</div>
							<div style="margin-left: 75px;">
								<div class="tempalte-operate">
									<div>
										<div class="ft14 ftwblod cl-balck">填写表单名称</div>
										<div class="flex alcenter mt12">
											<a-input placeholder="填写表单名称" v-model="name" />
										</div>
									</div>

									<div class="mt16">
										<div class="ft14 ftwblod cl-balck">填写表单标识符</div>
										<div class="flex alcenter mt12">
											<a-input placeholder="填写表单标识符" v-model="identifier" />
										</div>
									</div>

                  <div class="mt16">
                    <div class="ft14 ftwblod cl-balck">请选择作用域</div>
                    <div class="flex alcenter mt12">
                      <a-radio-group v-model="type">
                        <a-radio :value="1">长者</a-radio>
                        <a-radio :value="2">用户</a-radio>
                        <a-radio :value="3">服务人员</a-radio>
                      </a-radio-group>
                    </div>
                  </div>


<!--									<div class="mt16">-->
<!--										<div class="ft14 ftwblod cl-balck">请选择作用域</div>-->
<!--										<div class="flex alcenter mt12">-->
<!--											<a-radio-group v-model="scope">-->
<!--												<a-radio :value="1">小程序</a-radio>-->
<!--												<a-radio :value="2">后管</a-radio>-->
<!--											</a-radio-group>-->
<!--										</div>-->
<!--									</div>-->
									
									<div class="ft14 ftwblod cl-balck mt16">请编辑表单<span class="ft12 ml10 cl-notice">动态组件模版设置的时候只显示样式不调用真实数据</span></div>
									<div class="mt12" style="width: 100%;">
										<plugins-edit v-model="temps"></plugins-edit>
									</div>
									<!-- <div>
										<div class="flex alcenter space">
											<div class="ft14 ftwblod cl-balck">选择配色方案</div>
											
											<a-button @click="showTempDiv = true" type="danger">从模板库选择</a-button>
										</div>
										<div class="flex alcenter mt12">
											<span>
												<select-color title="主色调" v-model="temps.main_color"></select-color>
											</span>
											<span class="ml16">
												<select-color title="背景颜色" type="rgba" v-model="temps.home_bg"></select-color>
											</span>
										</div>
									</div>
									<div class="ft14 cl-main mt16">页面组件<span class="ft12 ml10 cl-notice">动态组件模版设置的时候只显示样式不调用真实数据</span></div>
									<div class="mt12">
										<plugins-edit v-model="temps.plugins"></plugins-edit>
									</div>
									<div class="ft14 cl-main mt16">底部导航<span class="ft12 ml10 cl-notice">底部“商城”和“我的”固定不动，可在中间添加修改导航标签，最多添加3个</span></div>
									<div class="mt12">
										<plugins-footers v-model="temps.footers"></plugins-footers>
									</div> -->

									<div class="mt30">
										<a-button type="primary" :loading="confirmLoading" @click="save()">保存</a-button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

		</a-spin>
	</div>
</template>

<script>
	import  pluginsEdit from './components/plugins.vue';
	import showMobile from './components/mobile.vue';
	import showAdmin from './components/admin.vue';
	export default {
		components:{
			pluginsEdit,
			showMobile,
			showAdmin,
		},
		data() {
			return {
				loading: false,
				confirmLoading: false,

				form_id: 0,
				name: "",
				identifier: '',
				scope: 1,
        type: 1,
				temps: [],
			}
		},
		created() {
			if (!this.$route.query.id) {
				this.form_id = 0;
			} else {
				this.form_id = parseInt(this.$route.query.id);
			}
			this.loaddata();
		},
		computed: {
			getTitle() {
				if (this.form_id == 0) {
					return '添加表单';
				} else {
					return '编辑表单';
				}
			}
		},
		methods: {
			loaddata() {
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('admin/showForm',{
					form_id:this.form_id,
				}).then(res=>{
					this.loading=false;
					if(res.detail){
						this.name=res.detail.name;
						this.identifier=res.detail.identifier;
						this.scope=res.detail.scope;
						this.type=res.detail.type;
						this.temps=res.detail.temps;
					}
				}).catch(res=>{
					console.log(res);
					this.loading=false;
					this.$router.go(-1);
				})
			},

			save() {
				if(!this.check()){
					return false;
				}
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('admin/saveForm',{
					form_id:this.form_id,
					name:this.name,
					identifier:this.identifier,
					scope:this.scope,
          type:this.type,
					datas:JSON.stringify(this.temps),
				}).then(res=>{
					this.$message.success('保存成功');
					this.$router.go(-1);
					this.loading=false;
				}).catch(res=>{
					console.log(res);
					this.loading=false;
				})
			},
			
			check(){
				if(this.name == ''){
					this.$message.error("请填写表单名称");
					return false;
				}
				if(this.identifier == ''){
					this.$message.error("请填写表单标识符");
					return false;
				}
				for(var i in this.temps){
					if(this.temps[i].valueName == ''){
						this.$message.error("请填写第"+(parseInt(i)+1)+"个表单的字段名");
						return false;
					}
					if(this.temps[i].valueType == ''){
						this.$message.error("请填写第"+(parseInt(i)+1)+"个表单的字段标识符");
						return false;
					}
					if(this.temps[i].key=='radio' || this.temps[i].key=='checkbox' || this.temps[i].key=='select'){
						if(this.temps[i].datas.length == 0){
							this.$message.error("请填写第"+(parseInt(i)+1)+"个表单的所需选择的数据");
							return false;
						}
					}
				}
				
				return true;
			}
		}
	}
</script>

<style>
	.tempalte-operate {
		width: 750px;
		height: 100%;
		background: #F9F9F9;
		border: 1px solid #F0F3F5;
		padding: 30px;
	}
</style>
